import React from "react";
import { BlockLoader } from "./block-loader";

export function BlocksLoader(page) {
  const { color, blocks, siteConfig } = page;

  return Array.isArray(blocks) ? (
    blocks.map(block => (
      <BlockLoader
        page={page}
        color={color}
        block={block}
        key={block.id}
        siteConfig={siteConfig}
      />
    ))
  ) : (
    <pre>blocks must be of type array. ${typeof blocks} given.</pre>
  );
}
