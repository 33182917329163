import React from "react";
import Layout from "../components/layout";
import { BlocksLoader } from "../components/blocks/blocks-loader";

export default function Page({ pageContext, location }) {
  const { blocks = [], color, siteConfig } = pageContext;
  return (
    <Layout
      pageContext={pageContext}
      siteConfig={siteConfig}
      path={location?.pathname}
    >
      <BlocksLoader color={color} blocks={blocks} siteConfig={siteConfig} />
    </Layout>
  );
}
